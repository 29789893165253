<template>
    <div>
        <canvas id="myChart" width="200" height="100" role="img"></canvas>
        <button @click="downloadChart" class="button is-primary m-5"> Download</button>
    </div>
</template>

<script>
import Chart from 'chart.js';

const colors = [ '#ff0000', '#ffa500', '#4b0082', '#008000', '#0000ff', '#ffff00', '#ee82ee']

const createSet = function( values, label, axisId, axisTitle, color, meterUnitId) {
    const yAxis = {
        display: true,
        type: 'linear',
        position: (axisId % 2 == 0) ? 'left' : 'right',
        scaleLabel: {
            display: true,
            labelString: axisTitle,
            fontColor: color
        },
        id: axisId.toString()
    }

    const dataSet = {
        label: label,
        borderColor: (color != undefined) ? color : '#7e7e7e',
        backgroundColor: '#ddd',
        data: values,
        yAxisID: axisId.toString(),
        pointRadius: 2,
        pointHoverRadius: 4,
        fill: false,
        id: meterUnitId
    }

    return {yAxis, dataSet}
}


// https://stackoverflow.com/a/59575616
const roundValue = function(val, decimals) {
    return Number(Math.round(Number(val + 'e' + decimals)) + 'e-' + decimals);
}


export default {
    props: ['data', 'dataName', 'yAxis'],
    data() {
        return {
            chart: null
        }
    },
    methods: {
        fixUnitAndData(unitSymbol, values) {
            let newSymbol = ""
            let newValues = []
            switch (unitSymbol) {
                case "Wh":
                    newSymbol = "kWh"
                    newValues = values.map(v => { return v/1000 })
                    break
                case "J":
                    newSymbol = "MJ"
                    newValues = values.map(v => { return v/1000000 })
                    break
                case "m^3":
                    newSymbol = "m³"
                    newValues = values
                    break
                case "m^3/h":
                    newSymbol = "m³/h"
                    newValues = values
                    break
                case "m^3/s":
                    newSymbol = "m³/s"
                    newValues = values
                    break
                default:
                    newSymbol = unitSymbol
                    newValues = values
            }
            return {symbol: newSymbol, values: newValues.map(v => roundValue(v, 3))}
        },
        reset() {
            this.chart.data.labels = []
            this.chart.data.datasets = []
            this.chart.options.scales.yAxes = []
            this.chart.update()
        },
        addDataSet(id, records, title, symbol, meterUnitId, graphColorHex='ff0000') {
            const fixedUnitAndValues = this.fixUnitAndData(symbol, records.values)

            const { yAxis, dataSet } = createSet(fixedUnitAndValues.values, title, id, fixedUnitAndValues.symbol, `#${graphColorHex}`/*colors[id % colors.length]*/, meterUnitId)

            if (this.chart.data.labels.length == 0) {
                this.chart.data.labels = records.timestamps
            }
            this.chart.options.scales.yAxes.push(yAxis)
            this.chart.data.datasets.push(dataSet)
            this.chart.update();
        },
        removeDataSet(meterUnitId) {//id) {
            // this.chart.data.datasets = this.chart.data.datasets.filter(obj => obj.yAxisID != id)
            // this.chart.options.scales.yAxes = this.chart.options.scales.yAxes
            //     .filter(obj => {
            //         return obj.id != id
            //     })
            this.chart.data.datasets = this.chart.data.datasets.filter(obj => obj.id != meterUnitId)

            const allAxisIds = []
            this.chart.data.datasets.forEach(obj => {
                if (!allAxisIds.includes(obj.yAxisID)) allAxisIds.push(obj.yAxisID)
            });

            this.chart.options.scales.yAxes = this.chart.options.scales.yAxes.filter(obj => allAxisIds.includes(obj.id))
            this.chart.update();
        },
        changePointSize() {
            this.chart.data.datasets.forEach((dataset) => {
                dataset.borderColor = '#00f'
            });
            this.chart.update();
        },
        downloadChart() {
            var link = document.createElement('a');
            link.download = 'filename.png';
            link.href = document.getElementById('myChart').toDataURL()
            link.click();
        },
        render() {
            const ctx = document.getElementById('myChart');
            this.chart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: [],
                    datasets: []
                },
                options: {
                    responsive: true,
                    tooltips: {
                        mode: 'index',
                        intersect: false,
                    },
                    hover: {
                        mode: 'nearest',
                        intersect: true
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                            type: 'time',
                            distribution: 'linear',
                            ticks: {
                                minRotation: 0,
                                maxRotation: 0,
                                autoSkipPadding: 10
                            }
                        }],
                        yAxes: [{
                            display: false,
                            scaleLabel: {
                                display: true,
                                labelString: 'Undefined'
                            },
                            id: -1
                        }]
                    }
                }
            });
        }
    },
    mounted() {
        this.render()
    }
}
</script>

<style>
</style>