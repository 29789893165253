
import { Component, Vue, Watch } from 'vue-property-decorator'

import MeterInfoCard from '@/components/MeterInfoCard.vue'
import DataGraph from '@/components/DataGraph.vue'
import DataTable from '@/components/DataTable.vue'
import { NodeInfo, Meter, MeterUnit, ShownUnit, Data } from '@/types/state'

type DataGraphComponent = Vue & {
    mounted: boolean,
    addDataSet(id: number, records: RecordsSplit, title: string, symbol: string, meterUnitId: number, graphColorHex: string): void,
    removeDataSet(id: number): void,
    reset(): void
}

type RecordsSplit = {
    timestamps: Array<Date>,
    values: number[]
}


@Component({
    components: {
      MeterInfoCard,
      DataGraph,
      DataTable
    }
})
export default class DataPage extends Vue {
  // searchValue = ''
  // aliasFilter = 0

  // containsTerm(target: string, term: string): boolean {
  //   return target.toLowerCase().includes(term.toLowerCase())
  // }

  // get aliasFilterIcon(): string {
  //   switch(this.aliasFilter) {
  //     case 0:   return 'mdi-sort-alphabetical-variant'
  //     case 1:   return 'mdi-sort-alphabetical-ascending'
  //     default:  return 'mdi-sort-alphabetical-descending'
  //   }
  // }

  isMounted = false

  labels = []
  records = []

  startDateMenu = false
  endDateMenu = false

  startDate = new Date(Date.now() - 1000*60*60*24*7).toISOString().substr(0, 10)
  endDate = new Date(Date.now()).toISOString().substr(0, 10)

  refreshGraphData(): void {
    this.dataGraph.reset()
    this.$store.commit('detailNode/SET_DATA_LOADED', false)

    this.shownUnitsWithinDateRange.forEach(u => {
        const id = u.meterUnitId
        const meterName = this.getMeterName(id)
        const unitName = this.getUnitName(id)
        const unitSymbol = this.getUnitSymbol(id)
        const graphColor = u.graphColorHex
        if (meterName && unitSymbol) {
            this.dataGraph.addDataSet(/*nu.meterUnitId*/this.convertStringToNumber(unitSymbol), this.splitRecordsToArrays(u.data), meterName + " (" + unitName + ")", unitSymbol, id, graphColor)
        }
    })

    this.$store.commit('detailNode/SET_DATA_LOADED', true)
  }

  @Watch("startDate")
  updateStartDateInState(): void {
      this.$store.commit('detailNode/SET_START_DATE', this.startDate)
  }
  
  @Watch("endDate")
  updateEndDateInState(): void {
      this.$store.commit('detailNode/SET_END_DATE', this.endDate)
  }


  formatDate(date: string): string {
    return date.substring(8, 10) + "/" + date.substring(5, 7) + "/" + date.substring(0, 4)
  }

  get startDateText(): string {
    return this.formatDate(this.startDate)
  }

  get endDateText(): string {
    return this.formatDate(this.endDate)
  }


  getMeterById(meterUnitId: number): Meter|undefined {
      return this.meters.find(m => m.unitIds.includes(meterUnitId))
  }

  getMeterName(meterUnitId: number): string|undefined {
      const meter = this.getMeterById(meterUnitId)
      return meter ? meter.name : undefined
  }

  getUnitSymbol(meterUnitId: number): string|undefined {
      const meter = this.getMeterById(meterUnitId)
      if (meter) {
          const meterUnit: MeterUnit|undefined = meter.displayedMeterUnitsWithData.find((dmu: { meterUnitId: number }) => dmu.meterUnitId == meterUnitId)
          return meterUnit ? meterUnit.symbol : undefined
      }
      return undefined
  }

  getUnitName(meterUnitId: number): string|undefined {
      const meter = this.getMeterById(meterUnitId)
      if (meter) {
          const meterUnit: MeterUnit|undefined = meter.displayedMeterUnitsWithData.find((dmu: { meterUnitId: number }) => dmu.meterUnitId == meterUnitId)
          return meterUnit ? meterUnit.referenceName : undefined
      }
      return undefined
  }


  splitRecordsToArrays(records: Array<Data>): RecordsSplit {
      return {
          timestamps: records.map(r => r.timestamp),
          values: records.map(r => r.value)
      }
  }


  fixValueBasedOnUnit(unitSymbol: string|undefined, value: number, roundToDecimals = 3): number {
      switch (unitSymbol) {
          case "Wh":    value/=1000; break
          case "J":     value/=1000000; break
          default:      break
      }
      return this.roundValue(value, roundToDecimals)
  }

  fixUnitSymbol(unitSymbol: string|undefined): string {
      switch(unitSymbol) {
          case "Wh":    return "kWh"
          case "J":     return "MJ"
          case "m^3":   return "m³"
          case "m^3/h": return "m³/h"
          case "m^3/s": return "m³/s"
          default:      return unitSymbol ? unitSymbol : ""
      }
  }

  // https://stackoverflow.com/a/59575616
  roundValue(val: number, decimals: number): number {
      return Number(Math.round(Number(val + 'e' + decimals)) + 'e-' + decimals);
  }


  get shownUnits(): Array<ShownUnit> {
      return this.$store.getters['detailNode/ShownUnits']
  }

  get shownUnitsWithinDateRange(): Array<ShownUnit> {
      return this.$store.getters['detailNode/ShownUnitsWithinDateRange']
  }

  getShownUnitTableData(shownUnit: ShownUnit): any {
      const dataFunc = shownUnit.dataFunc
      if (!dataFunc) return undefined
      if (!shownUnit.data || shownUnit.data.length == 0) return undefined

      const meterUnitId = shownUnit.meterUnitId
      const unitSymbol = this.getUnitSymbol(meterUnitId)

      let minRaw, maxRaw, calcRaw
      if (dataFunc == "Average") {
          const vals = shownUnit.data.map(d => d.value)
          minRaw = Math.min(...vals)
          maxRaw = Math.max(...vals)
          calcRaw = vals.reduce((a, b) => a + b)/vals.length
      } else {  // if dataFunc == "Difference"
          minRaw = shownUnit.data[0].value
          maxRaw = shownUnit.data[shownUnit.data.length - 1].value
          calcRaw = maxRaw - minRaw
      }

      const min = this.fixValueBasedOnUnit(unitSymbol, minRaw, shownUnit.decimals ?? 3)
      const max = this.fixValueBasedOnUnit(unitSymbol, maxRaw, shownUnit.decimals ?? 3)
      const calc = this.fixValueBasedOnUnit(unitSymbol, calcRaw, shownUnit.decimals ?? 3)


      const tableHeaderName = this.getMeterName(meterUnitId)
      const fixedUnitSymbol = this.fixUnitSymbol(unitSymbol)
      const tableHeaderVal = this.getUnitName(meterUnitId) + (fixedUnitSymbol != "" ? " [" + fixedUnitSymbol + "]" : "")

      let calcString = ""
      switch (dataFunc) {
          case "Average":
              calcString = "Gemiddelde"
              break
          case "Difference":
              calcString = "Verschil"
              break
          default:
              calcString = "-"
      }

      const itemHeader = {
          "name": tableHeaderName,
          "val": tableHeaderVal
      }

      const itemValues = [
          {
              "name": "Minimum",
              "val": min
          },
          {
              "name": calcString,
              "val": calc
          },
          {
              "name": "Maximum",
              "val": max
          }
      ]

      return { header: itemHeader, values: itemValues }
  }

  convertStringToNumber(str: string): number {
      let numStr = ""
      for (let c = 0; c < str.length; c++) {
          numStr += str.charCodeAt(c).toString() + " ";
      }
      return parseInt(numStr.replaceAll(" ", ""), 10)
  }


  @Watch('shownUnitsWithinDateRange', {deep: true})
  shownUnitsChanged(newUnits: Array<ShownUnit>, oldUnits: Array<ShownUnit>): void {
    if (this.$refs.dataGraph) {
        this.$store.commit('detailNode/SET_DATA_LOADED', false)

        if (newUnits.length != oldUnits.length) {
            const unitsWithinRange = this.shownUnitsWithinDateRange

            oldUnits.forEach(ou => {
                if (!newUnits.find(nu => nu.meterUnitId == ou.meterUnitId)) {
                    this.dataGraph.removeDataSet(ou.meterUnitId)
                }
            })

            newUnits.forEach(nu => {
                if (!oldUnits.find(ou => ou.meterUnitId == nu.meterUnitId)) {
                    const recs = unitsWithinRange.find(u => u.meterUnitId == nu.meterUnitId)
                    const meterName = this.getMeterName(nu.meterUnitId)
                    const unitName = this.getUnitName(nu.meterUnitId)
                    const unitSymbol = this.getUnitSymbol(nu.meterUnitId)
                    const graphColor = nu.graphColorHex
                    if (recs && meterName && unitSymbol) {
                        this.dataGraph.addDataSet(
                            this.convertStringToNumber(unitSymbol), this.splitRecordsToArrays(recs.data), meterName + " (" + unitName + ")", unitSymbol, nu.meterUnitId, graphColor
                        )
                    }
                }
            })
        } else {
            this.refreshGraphData()
        }

        this.$store.commit('detailNode/SET_DATA_LOADED', true)
    }
  }


  get detailNode(): NodeInfo {
    return this.$store.getters['detailNode/NodeInfo']
  }

  get meters(): Array<Meter> {
    return this.$store.getters['detailNode/Meters']
  }

  get isLoaded(): boolean {
    return this.$store.getters['detailNode/IsPreloaded']
  }

  get isDataLoaded(): boolean {
    return this.$store.getters['detailNode/IsDataLoaded']
  }

  get dataGraph(): DataGraphComponent {
      return this.$refs.dataGraph as DataGraphComponent
  }

  created(): void {
      this.isMounted = false
  }

  mounted(): void {
    this.isMounted = true
    this.$store.dispatch('detailNode/init')
  }


}
