
import { http } from '@/http-common'

import { Meter, ShownUnit, ShownUnitWithShouldUpdateFlag, MeterUnit } from '@/types/state'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
    components: {
    }
})
export default class DefaultHome extends Vue {
    @Prop()
    meter!: Meter

    checkedMeters: number[] = []

    fixUnitSymbol(unitSymbol: string) {
        switch (unitSymbol) {
            case "Wh":
                return "kWh"
            case "J":
                return "MJ"
            case "m^3":
                return "m³"
            case "m^3/h":
                return "m³/h"
            case "m^3/s":
                return "m³/s"
            default:
                return unitSymbol
        }
    }

    async updateMeterUnitData(unitId: number, updateIfPresent = false): Promise<void> {
        const data = await http.get(`/detail-node-data?meterUnitId=${unitId}&startDate=${this.dataStartDate}&endDate=${this.dataEndDate}`)

        if (data && data["data"]) {
            const meters: Array<Meter> = this.$store.getters['detailNode/Meters']

            const meterWithThisUnitId = meters.find(m => m.unitIds.includes(unitId))
            if (meterWithThisUnitId) {
                const meterUnitIdObj = meterWithThisUnitId.displayedMeterUnitsWithData.find((dmu: { meterUnitId: number }) => dmu.meterUnitId == unitId)
                if (meterUnitIdObj) {
                    const shownUnit: ShownUnit = {
                        meterUnitId: unitId,
                        data: data["data"],
                        dataFunc: meterUnitIdObj.dataFunc,
                        graphColorHex: meterUnitIdObj.graphColorHex,
                        decimals: meterUnitIdObj.decimals
                    }
                    const shownUnitWithFlag: ShownUnitWithShouldUpdateFlag = {shownUnit: shownUnit, shouldUpdate: updateIfPresent}
                    this.$store.commit('detailNode/TOGGLE_SHOWN_UNIT_WITH_DATA', shownUnitWithFlag)
                }
            }
        }
    }

    @Watch('checkedMeters')
    watchCheckedMeters(newVal: number[], oldVal: number[]): void {
        const bigger = newVal.length > oldVal.length ? newVal : oldVal
        const smaller = newVal.length <= oldVal.length ? newVal : oldVal
        const diff = bigger.filter(x => !smaller.includes(x))

        if (diff && diff.length) {
            const changedUnitId = diff[0]
            this.updateMeterUnitData(changedUnitId)
        }
    }

    @Watch('dataStartDate')
    @Watch('dataEndDate')
    updateAllMeterData() {
        this.checkedMeters.forEach(cm => {
            this.updateMeterUnitData(cm, true)
        });
    }

    get title(): string {
        return this.meter.name
    }

    get modelName(): string {
        return this.meter.modelName
    }

    get meterUnits(): Array<MeterUnit> {
        return this.meter.displayedMeterUnitsWithData
    }

    get statusColor(): string {
        return this.meterUnits?.length > 0 ? "green" : "red"
    }

    get statusText(): string {
        return `${this.meterUnits?.length} gegeven${this.meterUnits?.length > 0 ? "s" : ""}`
    }

    get dataStartDate(): Date {
        return this.$store.getters['detailNode/DataStartDate']
    }

    get dataEndDate(): Date {
        return this.$store.getters['detailNode/DataEndDate']
    }
}
